/*----------------------------------------*\
  Main
\*----------------------------------------*/

@import 'tailwindcss/base';
@import 'tailwindcss/components';

@import 'base/base.postcss';

@import 'components/container.postcss';
@import 'components/button.postcss';
@import 'components/form.postcss';
@import 'components/navigation.postcss';
@import 'components/notification.postcss';

@import 'tailwindcss/utilities';

.disabled {
	color: #a0a0a0;
	pointer-events:none;
}

a.externalLink {
	text-decoration-line: underline;
}

.linkButton {
	text-decoration-line: underline;
	cursor: pointer;
}

.linkButton:hover {
	color: #689397;
}

.tableIcon {
	height: 1.8rem;
	width: 1.8rem;
	background-repeat: no-repeat;
	background-size: 1.5rem;
}

.shareIcon { background-image: url("../icons/Icon_AddUser.svg"); }
.mobileRemoveIcon { background-image: url("../icons/trash.png"); }
.mobileDownloadIcon { background-image: url("../icons/download-circle.svg"); }
.tableSpinnerIcon { background-image: url("../icons/spinner32.svg"); }
.tableModelIcon { background-image: url("../icons/3dmode_icon.svg"); }
.tableImageIcon { background-image: url("../icons/Icon_Image.svg"); }
.tableACCIcon { background-image: url("../icons/Icon_ImportACC.svg"); }
.tableRevitIcon { background-image: url("../icons/Icon_ImportRevit.svg"); }
.tableRhinoIcon { background-image: url("../icons/Icon_ImportRhino.svg"); }
.tableSketchupIcon { background-image: url("../icons/Icon_ImportSketchup.svg"); }
.tableUnityIcon { background-image: url("../icons/Icon_ImportUnity.svg"); }
.tableErrorIcon { background-image: url("../icons/error32.png"); }

.clickableSpan {
    cursor: pointer;
}

.clickableSpan:hover {
    color: #888888;
}

.clickableText {
	text-decoration: underline;
}

.copyIcon {
	background-image: url("../icons/copy24.png");
	width: 24px;
	height: 24px;
	margin-left: 4pt;
	margin-right: 4pt;
}

.copyElement {
	float: left;
}

.inputOverride {
	max-width : 100%;
}

.mediumButton {
	max-width : 50%;
}

.autodeskSigninButton {
	line-height: 1.3333333;
	padding: 8pt 14pt 10pt;
	border-radius: 6px;
	border: 1px solid;
	border-color: #cccccc;
	margin: 1em;
}

.autodeskSigninButton:hover {
	background-color: #e6e6e6;
	border-color: #adadad;
}

.autodeskSigninLabel {
	text-align: right;
	width: 11em;
	background-image: url("../icons/Autodesk_Full.svg");
	background-repeat: no-repeat;
	background-size: 68%;
	background-position: 0 0.3em;
}

.greytext {
	color: #a09c99;
}

#ACCLoginSection {
	text-align: center;
	margin-top: 2em;
}

#treediv {
	overflow-x: auto;
	overflow-y: scroll;
	font-size: 9pt;
}

.treedivWideScreen {
	flex: 0 0 40%;
}

.treedivNarrowScreen {
	flex: 1 1 50%;
}

#forgeViewerInner {
	width: 100%;
	aspect-ratio: 1 / 1;
	position: relative;
}

.forgeViewerWideScreen {
	flex: 0 0 58%;
}

.forgeViewerNarrowScreen {
	flex: 1 1 50%;
}

.accDocumentPart {
	box-sizing: border-box;
	position: relative;
}

.middleContainer {
	max-width: 1600px;
}

#accDocuments {
	display: flex;
	flex-direction: row;
	width: 100%;
}

#shareACCModelWithGroupButton {
	margin-left: auto;
}

#shareDiv {
	position: relative;
}

#scenesContentSection {
	position: relative;
}

.backArrow {
	position: absolute;
	width: 32px;
	height: 32px;
	background-image: url("../icons/back_arrow.png");
	top: 15px;
	left: 15px;
	cursor: pointer;
}

.separator {
	margin-top: 20pt;
}

.copybutton {
	background-image: url("../icons/copy_bluegreen24.png");
	width: 24px;
	height: 24px;
	cursor: pointer;
	display: inline-block;
}

.copybutton:hover {
	background-image: url("../icons/copy_bluegreen_lighter24.png");
}

.image-container {
	display: flex;
	flex-wrap: nowrap; /* Prevents wrapping unless forced */
	justify-content: center; /* Centers images */
	gap: 1rem; /* Adds spacing */
	max-width: 90%; /* Prevents stretching */
	margin: auto; /* Centers the container */
	padding: 1rem;
}

.image-container img {
	width: 48%; /* Ensures images take almost half of available space */
	max-width: 100%;
	height: auto;
}

/* When screen width is less than 400px, stack images vertically */
@media (max-width: 600px) {
	.image-container {
		flex-direction: column; /* Stacks images on small screens */
		flex-wrap: wrap; /* Allows wrapping */
	}
	.image-container img {
		width: 100%; /* Makes images take full width */
	}
}
